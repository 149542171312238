#nprogress {
  top: 0;
  left: 0;
  width: 100%;
  height: 2.5px;
  z-index: 9999;
  position: fixed;
  pointer-events: none;
}
#nprogress .bar {
  height: 100%;
  background-color: var(--palette-primary-main);
  box-shadow: 0 0 2.5px var(--palette-primary-main);
}
#nprogress .peg {
  right: 0;
  opacity: 1;
  width: 100px;
  height: 100%;
  display: block;
  position: absolute;
  transform: rotate(3deg) translate(0px, -4px);
  box-shadow:
    0 0 10px var(--palette-primary-main),
    0 0 5px var(--palette-primary-main);
}
